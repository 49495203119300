<style lang="less" scoped>
#map_tip {
  position: fixed;
  right: 20%;
  margin-top: 15px;
  font-size: 16px;
}
</style>
<template>
  <div>
    <SvgLine v-if="showMapType === 1" ref="svgline" @map-finished="handleMapLoadFinished"
      @on-click-station="handleClickStation" />

    <GeoMap v-else ref="geoMap" @map-finished="handleMapLoadFinished" @on-click-station="handleClickStation" />

    <SvgStation ref="svgStation" :stationSvgModal.sync="stationSvgModal" :stationId="chooseStationId" :svgShowType="5"
      :searchSchedules="[]" @svg-finished="svgFinished" />
  </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import GeoMap from '@/components/map/GeoMap'
import SvgStation from '@/components/svg/SvgStation'
import { getPublisherAssetList } from '@/api/rim/asset'
import { getStationQuantity } from '@/api/rim/device'

export default {
  components: {
    SvgLine, GeoMap, SvgStation
  },
  data () {
    return {
      showMapType: this.$store.getters.token.publisherSetting.mediaType,
      mapFinished: false,
      stationSvgModal: false, // 弹窗显示SVG站点地图
      showRecordModal: false,
      chooseStationId: 0
      // publishResourceList: [] // 上刊发布的资源信息
    }
  },
  methods: {
    handleMapLoadFinished (val) {
      this.mapFinished = val
      // 仅限地铁线路图，需要隐藏其他线路
      if (val && this.showMapType === 1) {
        getPublisherAssetList().then(res => {
          if (res && !res.errcode) {
            this.$refs.svgline.hideOtherAsset(res.map(x => x.id))
          }
        })
      }
    },
    getStationQuantityData (assetId) {
      getStationQuantity({ assetId, bigmodel: 3 }).then(res => {
        if (res && !res.errcode) {
          this.LoadMapData(res)
        }
      })
    },
    LoadMapData (stations) {
      if (this.mapFinished) { // 验证地图是否加载完毕， 否则延迟1秒再次请求
        if (this.showMapType === 1) {
          const formatStations = stations.map(x => {
            return {
              quantity: x.quantity,
              stationId: x.stationId,
              stationName: x.stationName,
              inOrder: 1
            }
          })
          this.$refs.svgline.loadResourceStations(formatStations, 'order')
        } else {
          this.$refs.geoMap.loadResourceStations([])
        }
      } else {
        setTimeout(() => {
          this.LoadMapData(stations)
        }, 1000)
      }
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.chooseStationId = stationId
      this.stationSvgModal = true
      // setTimeout(() => {
      //   this.$refs.svgStation.choseSpinShow()
      // }, 3000)
    },
    svgFinished () {
      this.$refs.svgStation.choseSpinShow()
    }

  },
  computed: {
    curAssetId () {
      return this.$store.state.comAsset.curAssetId
    }
  },
  watch: {
    curAssetId: {
      deep: true,
      immediate: true,
      handler (val) {
        if (val) {
          this.getStationQuantityData(val)
        }
      }
    }
  }
}
</script>
